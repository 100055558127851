/* You can add global styles to this file, and also import other style files */

/* Importing Bootstrap SCSS file. */
@import "~bootstrap/scss/bootstrap";
@import "~bootstrap-icons/font/bootstrap-icons.css";

// $primaryColor: #212121;
$primaryColor: rgb(49, 49, 49);
$primaryColorTransparent: #2121212d;

$backgroundDisable: rgb(0 0 0 / 22%);

$secondColor: #5271ff;

$menuSelect: #5271ff;
// $menuSelect: #cd30a64d;
$menuSelectHover: #cd30a609;

html,
body {
  height: 100%;
}
body {
  margin: 0;
  font-family: Roboto, "Helvetica Neue", sans-serif;
}

.mat-toolbar {
  background: $primaryColor;
  color: #fff;
}

.no-margin {
  margin: 0 !important;
}

.no-padding {
  padding-left: 0px;
  padding-right: 0px;
}

.btn-primary {
  background-color: $primaryColor !important;
  border-color: $primaryColor !important;
}

.mat-table {
  background: #ffffff !important;
}

.mat-menu-panel {
  background: $primaryColor;
  box-shadow: 0px 0px 10px 1px $secondColor;
  margin-top: 10px;
  padding: 10px;
}

.shadow-div {
  box-shadow: 0px 0px 10px 1px $primaryColor;
}

.mat-cell,
.mat-footer-cell {
  color: #000 !important;
}

.mat-row,
mat-header-row,
mat-footer-row,
th.mat-header-cell,
td.mat-cell,
td.mat-footer-cell {
  border-bottom-color: rgb(0 0 0 / 20%) !important;
}

.mat-stroked-button:not(.mat-button-disabled) {
  border-color: $secondColor;
  color: white;
}

.mat-paginator,
.mat-paginator-page-size .mat-select-trigger {
  color: $primaryColor !important;
}

.mat-paginator,
.mat-paginator-page-size .mat-select-trigger {
  color: #ffffff !important;
}

mat-label {
  color: $primaryColor !important;
}

.mat-form-field-outline {
  color: $primaryColor !important;
}

mat-sidenav {
  background: $primaryColor !important;
}

.text-truncate-2-line {
  -webkit-line-clamp: 2;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  overflow: hidden;
}

.text-truncate-3-line {
  -webkit-line-clamp: 2;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  overflow: hidden;
}

mat-card {
  background-color: $primaryColor !important;
}

.background-color-primary {
  background-color: $primaryColor !important;
}

.mat-dialog-container {
  background: white;
  color: $primaryColor;
}

.mat-slide-toggle-bar {
  background-color: rgb(188 188 188 / 50%);
}
.mat-slide-toggle.mat-checked .mat-slide-toggle-thumb {
  background-color: $primaryColor;
}

.mat-slide-toggle.mat-checked .mat-slide-toggle-bar {
  background-color: #f44336;
}

mat-datepicker-toggle button {
  color: $primaryColor !important;
}

.mat-checkbox-inner-container {
  background: #21212129 !important;
}

.mat-button.mat-button-disabled.mat-button-disabled {
  color: rgb(161 161 161 / 55%);
}

p .no-margin {
  margin: 0px;
}

mat-option {
  color: $primaryColor !important;
  background-color: white !important;
  :hover {
    background-color: $primaryColorTransparent;
  }
  padding-left: 0px !important;
  padding-right: 0px !important;

  .mat-option-text {
    padding-left: 16px;
  }
}

.mat-autocomplete-panel .mat-option.mat-selected:not(.mat-active):not(:hover) {
  background: $primaryColorTransparent !important;
  color: $primaryColor !important;
}

.mat-autocomplete-panel {
  background: #ffffff;
  color: $primaryColor !important;
}

.mat-select-arrow {
  color: $primaryColor !important;
}

.mat-select-value {
  color: $primaryColor !important;
}

.mat-flat-button.mat-primary,
.mat-raised-button.mat-primary,
.mat-fab.mat-primary,
.mat-mini-fab.mat-primary {
  background-color: $primaryColor;
}

.mat-flat-button.mat-accent,
.mat-raised-button.mat-accent,
.mat-fab.mat-accent,
.mat-mini-fab.mat-accent {
  background-color: $secondColor;
  color: white;
}

.mat-progress-spinner.mat-accent circle,
.mat-spinner.mat-accent circle {
  stroke: $secondColor;
}

.mat-input-element:disabled,
.mat-form-field-type-mat-native-select.mat-form-field-disabled
  .mat-form-field-infix::after {
  color: $primaryColor;
}

.div-home-card {
  height: 50vh;
  background-size: cover;
  border-radius: 15px;
  background-color: rgba(0, 0, 0, 0.4);
  -webkit-box-shadow: 0px 0px 20px -1px rgba(0, 0, 0, 0.75);
  -moz-box-shadow: 0px 0px 20px -1px rgba(0, 0, 0, 0.75);
  box-shadow: 0px 0px 20px -1px rgba(0, 0, 0, 0.75);
}

.div-home-card-admin-dash {
  height: 30vh;
  background-size: cover;
  border-radius: 15px;
  background-color: rgba(0, 0, 0, 0.4);
  -webkit-box-shadow: 0px 0px 20px -1px rgba(0, 0, 0, 0.75);
  -moz-box-shadow: 0px 0px 20px -1px rgba(0, 0, 0, 0.75);
  box-shadow: 0px 0px 20px -1px rgba(0, 0, 0, 0.75);
}

.mat-card-transparent {
  background-color: #212121db !important;
}

.navbar-inverse {
  background-color: $primaryColor !important;
  border-color: $primaryColor !important;
}

.navbar {
  background-color: $primaryColor;
  border-color: $primaryColor;
  transition: background-color 1s ease;
}

.background-footer {
  background-color: $primaryColor;
  border-color: $primaryColor;
}

.mat-card-checkout-bar {
  background: #212529 !important;
}

.card .card-header {
  background: $primaryColor !important;
  color: white !important;
}

.card-shadow {
  -webkit-box-shadow: 0px 0px 20px -1px rgba(0, 0, 0, 0.75);
  -moz-box-shadow: 0px 0px 20px -1px rgba(0, 0, 0, 0.75);
  box-shadow: 0px 0px 20px -1px rgba(0, 0, 0, 0.75);
}

.mat-list-base .mat-list-item.mat-list-item-with-avatar,
.mat-list-base .mat-list-option.mat-list-item-with-avatar {
  height: 40px !important;
}

.menuSelect {
  background-color: $menuSelect !important;
  border-radius: 10px !important;
  box-shadow: 0px 0px 10px 1px #000000;
}

.menuNoSelect {
  background: unset !important;
}

.justifyText {
  text-align: justify !important;
}

.cdk-overlay-container {
  z-index: 1040 !important;
}

/* RESET DEFAULT STYLING */
* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

// ::-webkit-scrollbar {
//   width: 1vw;
// }

// ::-webkit-scrollbar-track {
//   background-color: #ffffff;
// }

// ::-webkit-scrollbar-thumb {
//   background-color: $primaryColor;
//   border: 0.3vw solid #ffffff;
//   border-radius: 5vw;
// }

.checkoutButton {
  position: fixed;
  top: 100px;
  right: 0px;
  z-index: 10;
  float: right;
  display: block;
  background: $primaryColor;
  width: 80px;
  border-radius: 10px 0px 0px 10px;
  height: 86px;
  color: white;
  padding: 4px;
}

.buttomMenu {
  position: fixed;
  top: 100px;
  left: 0px;
  z-index: 10;
  float: left;
  display: block;
  background: $primaryColor;
  width: 60px;
  border-radius: 0px 10px 10px 0px;
  height: 60px;
  color: white;
  padding: 4px;
}
.no-shadow {
  box-shadow: unset !important;
}

.mat-calendar-body-selected {
  background-color: $secondColor;
  color: #fff;
}

.p-font-12 {
  font-size: 12px;
}
.p-font-11 {
  font-size: 11px;
}
.p-font-10 {
  font-size: 10px;
}

.min-height-100 {
  min-height: 100vh;
}

.uppercase {
  text-transform: uppercase;
}

.mat-badge-small.mat-badge-above .mat-badge-content {
  top: -2px !important;
}

.mat-badge-small.mat-badge-overlap.mat-badge-after .mat-badge-content {
  right: -2px !important;
}

.mat-dialog-title {
  text-align: center !important;
}

p.underover {
  text-decoration: underline;
}

.pointer {
  cursor: pointer;
}

.mat-checkbox-checked.mat-accent .mat-checkbox-background {
  background-color: $secondColor;
}

.mat-flat-button.mat-primary.mat-button-disabled,
.mat-flat-button.mat-accent.mat-button-disabled,
.mat-flat-button.mat-warn.mat-button-disabled,
.mat-flat-button.mat-button-disabled.mat-button-disabled,
.mat-raised-button.mat-primary.mat-button-disabled,
.mat-raised-button.mat-accent.mat-button-disabled,
.mat-raised-button.mat-warn.mat-button-disabled,
.mat-raised-button.mat-button-disabled.mat-button-disabled,
.mat-fab.mat-primary.mat-button-disabled,
.mat-fab.mat-accent.mat-button-disabled,
.mat-fab.mat-warn.mat-button-disabled,
.mat-fab.mat-button-disabled.mat-button-disabled,
.mat-mini-fab.mat-primary.mat-button-disabled,
.mat-mini-fab.mat-accent.mat-button-disabled,
.mat-mini-fab.mat-warn.mat-button-disabled,
.mat-mini-fab.mat-button-disabled.mat-button-disabled {
  background-color: $backgroundDisable;
  color: black;
}
